<template>
  <div v-if="!busy">
    <vue-html2pdf
      ref="invoiceDownloadPdf"
       :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :margin="[10,0,10,0]"
        :paginate-elements-by-height="1400"
        filename="InvoiceDetail"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="900px"
    >
      <section slot="pdf-content">
        <section class="invoice-preview-wrapper container-fluid d-flex justify-content-center w-100">
          <b-row class="invoice-preview">
            <!-- Col: Left (Invoice Container) -->
            <b-col cols="12" xl="10" md="10">
              <b-card no-body class="invoice-preview-card">
                <!-- Header -->
                <b-card-body class="invoice-padding pb-0">
                  <div
                    class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                  >
                    <!-- Header: Left Content -->
                    <div>
                      <div class="logo-wrapper">
                        <common-tenant-logo :logo-type="'INVOICE_LOGOS'" />
                      </div>
                      <p class="card-text mb-0">
                        {{ companyName }}
                      </p>
                      <p class="card-text mb-0">
                        {{ addressObject.line1 }} {{ addressObject.line2 }} {{ addressObject.line3 }} Suite #{{ addressObject.suiteNumber }}
                      </p>
                      <p class="card-text mb-0">
                        {{ addressObject.city }}, {{ addressObject.state }} {{ addressObject.zipcode }}, {{ addressObject.country }}
                      </p>
                      <p class="card-text mb-0">
                        Phone: {{ phoneNumber }}; Fax: {{ faxNumber }}
                      </p>
                      <p class="card-text mb-0">
                        Email: {{ emailAddress }}
                      </p>
                    </div>

                    <!-- Header: Right Content -->
                    <div class="mt-md-0 mt-2">
                      <h4 class="invoice-title">
                        Invoice
                        <span class="invoice-number">#{{ invoiceData.no }}</span>
                      </h4>
                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title">
                          Date Issued:
                        </p>
                        <p class="invoice-date">
                          {{ invoiceData.issuedDate }}
                        </p>
                      </div>
                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title">
                          Due Date:
                        </p>
                        <p class="invoice-date">
                          {{ invoiceData.dueDate }}
                        </p>
                      </div>
                    </div>
                  </div>
                </b-card-body>

                <!-- Spacer -->
                <hr class="invoice-spacing">

                <!-- Invoice Client & Payment Details -->
                <b-card-body
                  v-if="invoiceData.client"
                  class="invoice-padding pt-0"
                >
                  <b-row class="invoice-spacing">
                    <!-- Col: Invoice To -->
                    <b-col cols="12" xl="6" class="p-0">
                      <h6 class="mb-2">
                        Invoice To:
                      </h6>
                      <h6 class="mb-25">
                        {{ invoiceData.client.name }}
                      </h6>
                      <p class="card-text mb-25">
                        {{ invoiceData.client.company }}
                      </p>
                      <p class="card-text mb-25">
                        {{ invoiceData.client.address }},
                        {{ invoiceData.client.country }}
                      </p>
                      <p class="card-text mb-25">
                        {{ invoiceData.client.contact }}
                      </p>
                      <p class="card-text mb-0">
                        {{ invoiceData.client.companyEmail }}
                      </p>
                    </b-col>

                    <!-- Col: Payment Details -->
                    <b-col
                      xl="6"
                      cols="12"
                      class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                    >
                      <div>
                        <h6 class="mb-2">
                          Payment Details:
                        </h6>
                        <table>
                          <tbody>
                            <tr>
                              <td class="pr-1">
                                Total Due:
                              </td>
                              <td>
                                <span class="font-weight-bold">{{
                                  paymentDetails.totalDue
                                }}</span>
                              </td>
                            </tr>
                            <tr>
                              <td class="pr-1">
                                Bank name:
                              </td>
                              <td>{{ paymentDetails.bankName }}</td>
                            </tr>
                            <tr>
                              <td class="pr-1">
                                Country:
                              </td>
                              <td>{{ paymentDetails.country }}</td>
                            </tr>
                            <tr>
                              <td class="pr-1">
                                IBAN:
                              </td>
                              <td>{{ paymentDetails.iban }}</td>
                            </tr>
                            <tr>
                              <td class="pr-1">
                                SWIFT code:
                              </td>
                              <td>{{ paymentDetails.swiftCode }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-body>

                <!-- Invoice Description: Table -->
                <b-table-lite
                  responsive
                  :items="invoiceDescription"
                  :fields="invoiceDescriptionFields"
                />

                <!-- Invoice Description: Total -->
                <b-card-body class="invoice-padding pb-0">
                  <div class="d-flex justify-content-end">
                    <div class="invoice-total-wrapper">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Subtotal:
                        </p>
                        <p class="invoice-total-amount">
                          ${{ itemsTotal.subtotal }}
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Discount:
                        </p>
                        <p class="invoice-total-amount">
                          ${{ itemsTotal.discount }}
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Invoice Total:
                        </p>
                        <p class="invoice-total-amount">
                          $${{ itemsTotal.total }}
                        </p>
                      </div>
                      <hr class="my-50">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Total Due:
                        </p>
                        <p class="invoice-total-amount text-success">
                          $${{ itemsTotal.totalDue }}
                        </p>
                      </div>
                    </div>
                  </div>
                </b-card-body>

                <!-- Spacer -->
                <hr class="invoice-spacing">

                <!-- Note -->
                <b-card-body class="invoice-padding pt-0">
                  <span class="font-weight-bold">Thank you for your business! We look forward to working with you again.</span>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </section>
      </section>
    </vue-html2pdf>
    <section class="invoice-preview-wrapper container-fluid">
      <div class="row">
        <div class="col-2 mt-1">
          <b-button
            variant="outline-success"
            class="mb-75 d-print-none"
            block
            @click="printInvoice"
          >
            Print Invoice
          </b-button>
        </div>
      </div>
      <b-row class="invoice-preview">
        <!-- Col: Left (Invoice Container) -->
        <b-col cols="12" xl="12" md="12">
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <common-tenant-logo :logo-type="'INVOICE_LOGOS'" />
                  </div>
                    <h6 class="mb-1 mt-4 mr-5">
                    {{ companyName }}
                  </h6>
                  <p class="card-text mb-0">
                    {{ addressObject.line1 }} {{ addressObject.line2 }} {{ addressObject.line3 }} Suite #{{ addressObject.suiteNumber }}
                  </p>
                  <p class="card-text mb-0">
                    {{ addressObject.city }}, {{ addressObject.state }} {{ addressObject.zipcode }}, {{ addressObject.country }}
                  </p>
                  <p class="card-text mb-0">
                    Phone: {{ phoneNumber }}
                  </p>
                  <p class="card-text mb-0">
                    Fax: {{ faxNumber }}
                  </p>
                  <p class="card-text mb-0">
                    Email: {{ emailAddress }}
                  </p>
                </div>

                <!-- Header: Right Content -->
                <div class="mt-md-0 mt-2">
                  <h4 class="invoice-title">
                    Invoice
                    <span class="invoice-number">#{{ invoiceData.no }}</span>
                  </h4>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">
                      Date Issued:
                    </p>
                    <p class="invoice-date">
                      {{ invoiceData.issuedDate }}
                    </p>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">
                      Due Date:
                    </p>
                    <p class="invoice-date">
                      {{ invoiceData.dueDate }}
                    </p>
                  </div>
                  <h6 class="mb-1 mt-4 mr-5">
                    Invoice To:
                  </h6>
                  <h6>
                    {{ invoiceData.client.company }}
                  </h6>
                  <p class="card-text mb-25">
                    {{ invoiceData.client.address1 }},
                  </p>
                  <p class="card-text mb-25">
                    {{ invoiceData.client.address2 }}
                  </p>
                  <p class="card-text mb-25">
                    {{ invoiceData.client.contact }}
                  </p>
                  <p class="card-text mb-0">
                    {{ invoiceData.client.companyEmail }}
                  </p>
                </div>
              </div>
            </b-card-body>

            <!-- Invoice Description: Table -->
            <b-table-lite
              responsive
              :items="invoiceDescription"
              :fields="invoiceDescriptionFields"
            />

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <div class="d-flex justify-content-end">
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Subtotal:
                    </p>
                    <p class="invoice-total-amount">
                      ${{ paymentDetails.totalDue }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Discount:
                    </p>
                    <p class="invoice-total-amount">
                      ${{ itemsTotal.discount }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Invoice Total:
                    </p>
                    <p class="invoice-total-amount">
                      ${{ paymentDetails.totalDue }}
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total Due:
                    </p>
                    <p class="invoice-total-amount text-success">
                      ${{ itemsTotal.totalDue }}
                    </p>
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span>Thank you for your business</span>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <invoice-sidebar-send-invoice />
      <invoice-sidebar-add-payment />
    </section>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import invoiceService from '@/http/requests/finance/invoiceService'
import Pageable from '@robustshell/components/Grid/pageable'
import { VBToggle, BBreadcrumb } from 'bootstrap-vue'
import VueHtml2pdf from 'vue-html2pdf'
import { formatDate } from '@core/utils/filter'
import CommonTenantLogo from '@/@core/layouts/components/CommonTenantLogo.vue'
import useTenantSettigns from '@/libs/app/tenant'
import InvoiceSidebarSendInvoice from '../InvoiceSidebarSendInvoice.vue'
import InvoiceSidebarAddPayment from '../InvoiceSidebarAddPayment.vue'

export default {
  directives: {
    'b-toggle': VBToggle,
    BBreadcrumb,
  },
  components: {
    InvoiceSidebarAddPayment,
    InvoiceSidebarSendInvoice,
    VueHtml2pdf,
    'common-tenant-logo': CommonTenantLogo,
  },
  setup() {
    const {
      companyName,
      addressObject,
      emailAddress,
      phoneNumber,
      faxNumber,
    } = useTenantSettigns()
    return {
      companyName,
      addressObject,
      emailAddress,
      phoneNumber,
      faxNumber,
    }
  },
  data: () => ({
    appName: '',
    paymentDetails: {
    },
    busy: true,
    invoiceData: {},
    invoiceDescription: [],
    invoiceDescriptionFields: [
      { key: 'costGroup', label: 'Cost Group', thStyle: { width: '300px' } },
      { key: 'itemGroupName', label: 'Item' },
      {
        key: 'quantity',
        label: 'Quantity',
        class: 'text-right',
        thStyle: { width: '120px' },
      },
      {
        key: 'rate',
        label: 'Rate',
        class: 'text-right',
        thStyle: { width: '120px' },
      },
      {
        key: 'discount',
        label: 'Discount',
        class: 'text-right',
        thStyle: { width: '120px' },
      },
      {
        key: 'totalCost',
        label: 'Total Cost',
        class: 'text-right',
        thStyle: { width: '150px' },
      },
    ],
    itemsTotal: {
      subtotal: 0,
      discount: 0,
      total: 0,
      totalDue: 0,
    },
    pageable: new Pageable({}),
  }),
  mounted() {
    this.getInvoiceDataItems()
  },
  methods: {
    setPagination(context) {
      this.pageable.setContext(context)
    },
    printInvoice() {
      document.title = `PrepShipHub_Invoice_No_${this.invoiceData.no}`
      window.print()
    },
    getInvoiceDataItems() {
      this.busy = true
      invoiceService
        .getDataForPrintInvoice(this.$route.params.id)
        .then(result => {
          const { data } = result
          this.invoiceData = {
            id: data.invoiceNo,
            no: data.invoiceNo,
            issuedDate: formatDate(data.invoiceDate),
            client: {
              address1: `${data.customerAddress.line1}`,
              address2: `${data.customerAddress.city}, ${data.customerAddress.state} ${data.customerAddress.zipcode}, ${data.customerAddress.country}`,
              company: data.companyName,
              companyEmail: data.customerEmail,
              country: data.customerAddress.country,
              contact: data.customerPhone,
              name: '',
            },
            service: 'Ship',
            total: data.invoiceAmount,
            avatar: '',
            invoiceStatus: data.status,
            balance: `$${data.totalPaid}`,
            dueDate: formatDate(data.dueDate),
          }
          this.paymentDetails = {
            totalDue: data.invoiceAmount,
            bankName: '',
            country: data.customerAddress.country,
            iban: '',
            swiftCode: '',
          }
          this.itemsTotal.totalDue = data.totalDue
          data.costGroups.forEach(element => {
            this.itemsTotal.subtotal += element.totalCost
            this.itemsTotal.total += element.totalCost

            const item = {
              costGroup: element.costGroupName,
              itemGroupName: element.itemGroupName,
              notes: element.notes,
              rate: element.changeType === 'refund' ? `($${element.chargeRate})` : `$${element.chargeRate}`,
              quantity: element.quantity,
              discount: `${element.discount}%`,
              totalCost: element.changeType === 'refund' ? `($${element.totalCost})` : `$${element.totalCost}`,
            }
            this.invoiceDescription.push(item)
          })
          this.busy = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
