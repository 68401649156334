<template>
  <div>
    <b-img v-if="!isLink" :src="logo" alt="PrepShipHub" :height="height" />
    <a v-else-if="url" :href="url" target="blank">
      <b-img :src="logo" alt="PrepShipHub" :height="height" />
    </a>
  </div>
</template>
<script>
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import useTenantSettigns from '@/libs/app/tenant'

export default {
  props: {
    height: {
      type: String,
      default: '128px',
    },
    isLink: {
      type: Boolean,
      default: false,
    },
    logoType: {
      type: String,
      default: '',
    },
  },
  setup() {
    const {
      tenantName, tenantWebSiteUrl, footerLogoUrl, invoiceLogoUrl, tenantLogoUrl,
    } = useTenantSettigns()
    return {
      tenantName,
      tenantWebSiteUrl,
      footerLogoUrl,
      invoiceLogoUrl,
      tenantLogoUrl,
    }
  },
  computed: {
    logo() {
      let url = ''
      if (this.logoType === 'FOOTER_LOGOS' && this.footerLogoUrl && this.footerLogoUrl.trim() !== '') {
        url = this.footerLogoUrl
      } else if (this.logoType === 'INVOICE_LOGOS' && this.invoiceLogoUrl && this.invoiceLogoUrl.trim() !== '') {
        url = this.footerLogoUrl
      } else if (this.logoType === 'TENANT_LOGOS' && this.tenantLogoUrl && this.tenantLogoUrl.trim() !== '') {
        url = this.footerLogoUrl
      } else if (this.tenantName && this.tenantName !== '') {
        url = require(`@/assets/images/logo/logo-${this.tenantName}.svg`)
      } else {
        url = require('@/assets/images/logo/logo.svg')
      }
      return url
    },
    url() {
      return this.tenantWebSiteUrl ?? '#'
    },
  },
}
</script>
