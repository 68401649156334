import { ref } from '@vue/composition-api'
import find from 'lodash/find'
import { getUserData } from '@/auth/utils'
import managedKeysEnum from '@/enums/managedKeysEnum'

export default function useTenantSettigns() {
  const currentUser = getUserData()
  const currentTenant = JSON.parse(localStorage.getItem('currentTenant'))
  const logoUrls = find(currentTenant, managedKeysEnum.PUBLIC_RESOURCE.key)
  const footerLogoUrl = logoUrls[managedKeysEnum.PUBLIC_RESOURCE.key].extraObject.FOOTER_LOGOS
  const tenantLogoUrl = logoUrls[managedKeysEnum.PUBLIC_RESOURCE.key].extraObject.TENANT_LOGOS
  const invoiceLogoUrl = logoUrls[managedKeysEnum.PUBLIC_RESOURCE.key].extraObject.INVOICE_LOGOS
  // Tenant Name - Logo
  const tenantId = currentUser.tenantId.toLowerCase()
  const tenantName = ref(tenantId)

  // Tenant Company Name
  let tenantCompanyName = find(currentTenant, managedKeysEnum.TENANTCOMPANYNAME.key)
  tenantCompanyName = tenantCompanyName[managedKeysEnum.TENANTCOMPANYNAME.key].extraObject
  const companyName = ref(tenantCompanyName.name)

  // Tenant Web Site URL
  let tenantWebSite = find(currentTenant, managedKeysEnum.TENANTWEBSITE.key)
  tenantWebSite = tenantWebSite[managedKeysEnum.TENANTWEBSITE.key].value
  const tenantWebSiteUrl = ref(tenantWebSite)

  // Tenant Address
  let tenantAddress = find(currentTenant, managedKeysEnum.WAREHOUSEADDRESS.key)
  tenantAddress = tenantAddress[managedKeysEnum.WAREHOUSEADDRESS.key].extraObject
  const line2 = tenantAddress.line2 ? tenantAddress.line2 : ''
  tenantAddress = `${tenantAddress.line1} ${line2} Suite #${currentUser.suiteNumber}, ${tenantAddress.city}, ${tenantAddress.state} ${tenantAddress.zipcode}, ${tenantAddress.country} `
  const address = ref(tenantAddress)

  // Tenant Address Object
  let tenantAddressObject = find(currentTenant, managedKeysEnum.WAREHOUSEADDRESS.key)
  tenantAddressObject = tenantAddressObject[managedKeysEnum.WAREHOUSEADDRESS.key].extraObject
  tenantAddressObject = {
    country: tenantAddressObject.country,
    city: tenantAddressObject.city,
    state: tenantAddressObject.state,
    zipcode: tenantAddressObject.zipcode,
    line1: tenantAddressObject.line1,
    line2: tenantAddressObject.line2,
    line3: tenantAddressObject.line3,
    suiteNumber: currentUser.suiteNumber,
  }
  const addressObject = ref(tenantAddressObject)

  // Email Address
  const emailAddress = ref('')
  let tenantEmailAddress = find(currentTenant, managedKeysEnum.WAREHOUSEEMAIL.key)
  if (tenantEmailAddress) {
    tenantEmailAddress = tenantEmailAddress[managedKeysEnum.WAREHOUSEEMAIL.key].extraObject
    emailAddress.value = tenantEmailAddress.email
  }

  // Phone Number
  const phoneNumber = ref('')
  let tenantPhoneNumber = find(currentTenant, managedKeysEnum.WAREHOUSEWORKPHONE.key)
  if (tenantPhoneNumber) {
    tenantPhoneNumber = tenantPhoneNumber[managedKeysEnum.WAREHOUSEWORKPHONE.key].extraObject
    phoneNumber.value = tenantPhoneNumber.phone
  }

  // Fax Number
  const faxNumber = ref('')
  let tenantFaxNumber = find(currentTenant, managedKeysEnum.WAREHOUSEWORKFAX.key)
  if (tenantFaxNumber) {
    tenantFaxNumber = tenantFaxNumber[managedKeysEnum.WAREHOUSEWORKFAX.key].extraObject
    faxNumber.value = tenantFaxNumber.phone
  }

  return {
    tenantName,
    companyName,
    tenantWebSiteUrl,
    address,
    addressObject,
    emailAddress,
    phoneNumber,
    faxNumber,
    footerLogoUrl,
    tenantLogoUrl,
    invoiceLogoUrl,
  }
}
